import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/nivea.png";
import emotion from "../../Assets/Projects/images-ty.jpg";
import editor from "../../Assets/Projects/heaven uto.png";
import chatify from "../../Assets/Projects/kiwis-stock management system.png";
import suicide from "../../Assets/Projects/leafy origen.png";
import bitsOfCode from "../../Assets/Projects/zoozook.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Heaven automation"
              description="Crafted an intelligent CRM solution for seamless lead management and service tracking. Delivered both mobile and web apps designed for efficiency and real-time collaboration. Empowering businesses to automate workflows and drive customer success."
              ghLink=""
              demoLink="https://heavenautomation.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="UAE based Ecommerce-zoozook"
              description="Built a feature-rich UAE-based eCommerce platform tailored for modern retail needs. Designed to handle product management, secure payments, and customer engagement seamlessly. Powering local businesses with scalable and user-friendly digital commerce solutions."
              ghLink=""
              demoLink="https://zoozook.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Leafy Origen"
              description="Developed a complete ERP solution for Leafy Origen — an emerging FMCG brand. Integrated modules for inventory, sales, finance, and a smart POS system for retail operations. Enabling end-to-end business automation from factory to shelf."
              ghLink=""
              demoLink="https://leafyorigen.com/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="DR nivea"
              description="Designed and developed a user-friendly online appointment booking app for Dr. Nivea, a dental care specialist. The app enables patients to book consultations, manage appointments, and receive timely notifications — making dental care more accessible and organized."
              ghLink="/Plant_AI"
              demoLink="https://www.drniveasdentalcare.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Tuition Masters — Online Tuition Platform"
              description="Developed a simple and effective online tuition platform for Tuition Masters. Designed to connect students with expert tutors for personalized learning. The system supports class scheduling, online payments, and study material management — making education accessible anytime, anywhere."
              
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="SAAS product-Kiwistron"
              description="Built a scalable SaaS-based Billing and Stock Management System for Kiwistron. The platform helps businesses manage invoicing, inventory tracking, and real-time stock monitoring from anywhere. Designed for flexibility, automation, and business growth."
               
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
