import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Mohammed </span>
            from <span className="purple"> Banglore, India.</span>
            <br />
            Successfully coordinated and delivered 20+ tech projects end-to-end.
Skilled in managing cross-functional teams & driving agile processes.
            <br />
            
Ensured on-time delivery with quality execution and client satisfaction.
Bridging the gap between ideas, teams, and seamless product delivery.
            <br />
            <br />
            Apart from My role, some other activities that I love to learn to upskill me in cutting edge technologies.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Mobile App development
            </li>
            <li className="about-activity">
              <ImPointRight /> Web app development
            </li>
            <li className="about-activity">
              <ImPointRight /> Cloud(AWS,CONTABO,AZURE,GCP)
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "A project manager without tech knowledge is like a captain sailing without a compass"{" "}
          </p>
          <footer className="blockquote-footer">Mohammed azis</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
